import React from 'react';

import {
  FeaturedImageWrapper,
  ForegroundImagesWrapper,
} from '@zola/zola-ui/src/components/PreAuthV3/FeaturedImage.styles';
import Image from 'next/image';

import { Container, BgImgWrapper } from './NextBackgroundForegroundImages.styles';

export type NextBackgroundForegroundImagesProps = {
  className?: string;
  foregroundImg?: {
    src: string;
    alt: string;
    objectFit?: 'contain' | 'cover';
  };
  backgroundImg?: {
    src: string;
    alt: string;
    objectFit?: 'contain' | 'cover';
  };
  bgColor?: string;
  priority: boolean;
};

const NextBackgroundForegroundImages = ({
  className,
  foregroundImg,
  backgroundImg,
  bgColor,
  priority,
}: NextBackgroundForegroundImagesProps): JSX.Element => (
  <Container className={className}>
    <FeaturedImageWrapper>
      <BgImgWrapper bgColor={bgColor}>
        {backgroundImg && (
          <Image
            src={backgroundImg.src}
            alt={backgroundImg.alt}
            sizes="(max-width: 768px) 100vw, 66vw"
            layout="fill"
            objectFit={backgroundImg.objectFit || 'cover'}
            priority={priority}
          />
        )}
      </BgImgWrapper>
      {foregroundImg && (
        <ForegroundImagesWrapper isWithBackgroundImage={Boolean(backgroundImg)}>
          <Image
            src={foregroundImg.src}
            alt={foregroundImg.alt}
            sizes="(max-width: 768px) 100vw, 66vw"
            layout="fill"
            objectFit={foregroundImg.objectFit || 'contain'}
            priority={priority}
          />
        </ForegroundImagesWrapper>
      )}
    </FeaturedImageWrapper>
  </Container>
);

export default NextBackgroundForegroundImages;
