import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { COLORS3, MEDIA_QUERY, SPACING } from '@zola/zola-ui/src/styles/emotion';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  ${MEDIA_QUERY.DESKTOP} {
    padding: ${SPACING.LG} ${SPACING.LG} ${SPACING.LG} 0;
  }
`;

export const BgImgWrapper = styled.div<{ bgColor?: string }>`
  background-color: ${p => (p.bgColor ? p.bgColor : COLORS3.BLACK_015)};
  border-radius: 16px;
  height: 100%;
  min-width: 100%;
  overflow: hidden !important;
  position: relative;
`;

export const differentiationStyleOverrides = css`
  padding: 0 !important;
`;
